import Logo from "../../components/app/logo";
import { Component } from "react";
import AuthLayout from "../../components/layouts/auth";
import { NextSeo } from "next-seo";
import SocialButtons from "../../components/login/socialButtons";
import LoginForm from "../../components/login/loginForm";
import useSiteTheme from "../../hooks/useSiteTheme";

const Login = () => {
  const { toggleTheme } = useSiteTheme();
  return (
    <>
      <NextSeo title={"Login"} />
      <div className="flex flex-row w-full h-screen">
        <div className={"w-40 absolute top-8 left-8"}>
          <Logo />
        </div>
        <div className={"w-screen/2 h-screen justify-center flex relative"}>
          <div className={"flex flex-col h-full justify-center w-1/2"}>
            <div className={"mb-8"}>
              <h3
                className={
                  "text-3xl mb-4 font-medium dark:text-white text-gray-700"
                }
              >
                Login
              </h3>
              <p className={"w-4/5 dark:text-gray-400 text-gray-500"}>
                Welcome back! Please enter an email and password
              </p>
            </div>
            <LoginForm />
            <SocialButtons />
            <footer
              className={
                "absolute flex flex-row items-center bottom-8 left-10 right-10 flex flex-row text-zinc-500 text-sm"
              }
            >
              <div>&copy; {new Date().getFullYear()} SkyPBX, LLC</div>
              <span
                className={
                  "ml-3 p-2 px-3 rounded hover:bg-zinc-100/50 dark:hover:bg-zinc-900 text-primary select-none cursor-pointer"
                }
                onClick={() => toggleTheme()}
              >
                Change Theme
              </span>
              <a
                href={"https://support.skypbx.com"}
                target="_blank"
                className={
                  "ml-auto flex flex-row items-center leading-none hover:text-white"
                }
                rel="noreferrer"
              >
                <i className="fa-solid fa-headset mr-2 text-lg" />
                <span>Got an issue? Contact Support</span>
              </a>
            </footer>
          </div>
        </div>
        <div
          className={"w-screen/2 h-screen"}
          style={{
            backgroundImage:
              "url(https://images.unsplash.com/photo-1550745165-9bc0b252726f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80)",
          }}
        />
      </div>
    </>
  );
};

Login.getLayout = function getLayout(page: Component) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default Login;
