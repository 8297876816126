const AuthLayout = ({children}) => {
    return (
        <>
            <div className={'flex flex-row'}>
                <div className={`flex w-full relative overflow-hidden`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default AuthLayout
