import { useAuth } from "../../lib/auth";

const SocialButtons = () => {
  const { signInWithGoogle, signInWithFacebook, signInWithMicrosoft } =
    useAuth();
  return (
    <div className={"flex flex-row relative pt-8"}>
      <div className={"absolute w-full top-0 border-t dark:border-gray-700"}>
        <span
          className={
            "absolute -top-2.5 left-1/2 -translate-x-1/2 dark:bg-gray-900 bg-white text-gray-500 dark:text-gray-400 px-3 text-sm"
          }
        >
          Login with
        </span>
      </div>
      <button
        className={
          "rounded flex flex-1 items-center justify-center p-3 border dark:border-0 dark:bg-white text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200"
        }
        onClick={() => signInWithGoogle()}
      >
        {/*<i className={"fa-brands fa-google"} />*/}
        <svg
          height="100%"
          viewBox="0 0 20 20"
          width="100%"
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          className={"w-4"}
        >
          <path
            d="M19.6 10.23c0-.82-.1-1.42-.25-2.05H10v3.72h5.5c-.15.96-.74 2.31-2.04 3.22v2.45h3.16c1.89-1.73 2.98-4.3 2.98-7.34z"
            fill="#4285F4"
          ></path>
          <path
            d="M13.46 15.13c-.83.59-1.96 1-3.46 1-2.64 0-4.88-1.74-5.68-4.15H1.07v2.52C2.72 17.75 6.09 20 10 20c2.7 0 4.96-.89 6.62-2.42l-3.16-2.45z"
            fill="#34A853"
          ></path>
          <path
            d="M3.99 10c0-.69.12-1.35.32-1.97V5.51H1.07A9.973 9.973 0 000 10c0 1.61.39 3.14 1.07 4.49l3.24-2.52c-.2-.62-.32-1.28-.32-1.97z"
            fill="#FBBC05"
          ></path>
          <path
            d="M10 3.88c1.88 0 3.13.81 3.85 1.48l2.84-2.76C14.96.99 12.7 0 10 0 6.09 0 2.72 2.25 1.07 5.51l3.24 2.52C5.12 5.62 7.36 3.88 10 3.88z"
            fill="#EA4335"
          ></path>
        </svg>
      </button>
      <button
        className={
          "rounded flex flex-1 items-center justify-center ml-5 mr-2.5 p-3 border dark:border-0 dark:bg-white text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200"
        }
        onClick={() => signInWithFacebook()}
      >
        {/*<i className={"fa-brands fa-facebook"} />*/}
        <svg
          height="100%"
          viewBox="0 0 20 20"
          width="100%"
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          className={"w-4"}
        >
          <path
            d="M18.007 19c.55 0 .993-.444.993-.993V1.993A.992.992 0 0018.007 1H1.993A.992.992 0 001 1.993v16.014c0 .55.444.993.993.993h16.014zm-4.587 0v-6.97h2.34l.35-2.717h-2.69V7.578c0-.786.218-1.322 1.346-1.322h1.438v-2.43a18.915 18.915 0 00-2.096-.108c-2.073 0-3.494 1.267-3.494 3.59v2.005H8.268v2.717h2.346V19h2.806z"
            fill="#3B5998"
            fillRule="evenodd"
          ></path>
        </svg>
      </button>
      <button
        className={
          "rounded flex flex-1 items-center justify-center mr-5 ml-2.5 p-3 border dark:border-0 dark:bg-white text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200"
        }
        onClick={() => signInWithMicrosoft()}
      >
        {/*<i className={"fa-brands fa-microsoft"} />*/}
        <svg
          height="100%"
          viewBox="0 0 20 20"
          width="100%"
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          className={"w-4"}
        >
          <g fill="none">
            <path d="M0 0h9.504v9.504H0z" fill="#F25022"></path>
            <path d="M10.496 0H20v9.504h-9.504z" fill="#7FBA00"></path>
            <path d="M0 10.496h9.504V20H0z" fill="#00A4EF"></path>
            <path d="M10.496 10.496H20V20h-9.504z" fill="#FFB900"></path>
          </g>
        </svg>
      </button>
      <button
        className={
          "rounded flex flex-1 items-center justify-center p-3 border dark:border-0 dark:bg-white text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200"
        }
        onClick={() => alert("Apple sign in")}
      >
        {/*<i className={"fa-brands fa-microsoft"} />*/}
        <svg
          height="100%"
          viewBox="0 0 20 24.6"
          width="100%"
          preserveAspectRatio="xMidYMid meet"
          focusable="false"
          className={"w-4"}
        >
          <path
            d="M19.4,8.4c-0.1,0.1-2.7,1.5-2.7,4.7c0,3.6,3.2,4.9,3.3,5c0,0.1-0.5,1.8-1.7,3.5c-1.1,1.5-2.1,3-3.8,3s-2.1-1-4-1
	c-1.9,0-2.5,1-4.1,1s-2.6-1.4-3.8-3.1c-1.4-2-2.6-5.2-2.6-8.1C0,8.5,3.1,6,6.2,6c1.6,0,3,1.1,4,1.1c1,0,2.5-1.1,4.3-1.1
	C15.2,5.9,17.7,6,19.4,8.4z M13.6,3.9c0.8-0.9,1.3-2.2,1.3-3.4c0-0.2,0-0.4,0-0.5c-1.2,0-2.7,0.8-3.6,1.9c-0.7,0.8-1.4,2.1-1.4,3.3
	c0,0.2,0,0.4,0,0.4c0.1,0,0.2,0,0.3,0C11.4,5.7,12.8,4.9,13.6,3.9z"
          />
        </svg>
      </button>
    </div>
  );
};

export default SocialButtons;

// <svg version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
// 	 width="814px" height="1000px" viewBox="0 0 814 1000" enable-background="new 0 0 814 1000" xml:space="preserve">
// <path d="M788.1,340.9c-5.8,4.5-108.2,62.2-108.2,190.5c0,148.4,130.3,200.9,134.2,202.2c-0.6,3.2-20.7,71.9-68.7,141.9
// 	c-42.8,61.6-87.5,123.1-155.5,123.1s-85.5-39.5-164-39.5c-76.5,0-103.7,40.8-165.9,40.8s-105.6-57-155.5-127
// 	C46.7,790.7,0,663,0,541.8c0-194.4,126.4-297.5,250.8-297.5c66.1,0,121.2,43.4,162.7,43.4c39.5,0,101.1-46,176.3-46
// 	C618.3,241.7,720.7,244.3,788.1,340.9z M554.1,159.4c31.1-36.9,53.1-88.1,53.1-139.3c0-7.1-0.6-14.3-1.9-20.1
// 	c-50.6,1.9-110.8,33.7-147.1,75.8c-28.5,32.4-55.1,83.6-55.1,135.5c0,7.8,1.3,15.6,1.9,18.1c3.2,0.6,8.4,1.3,13.6,1.3
// 	C464,230.7,521.1,200.3,554.1,159.4z"/>
// </svg>
