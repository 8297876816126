import { useForm } from "react-hook-form";
import { useState } from "react";
import Link from "next/link";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm({ mode: "onChange" });
  const onSubmit = (data) => console.log(data);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={"relative flex flex-col mb-8"}>
        <label
          className={
            "dark:bg-gray-900 bg-white text-gray-500 dark:text-gray-400 px-2 text-sm absolute -top-2.5 left-2"
          }
        >
          Email
        </label>
        <input
          type={"text"}
          className={
            "w-full rounded border-gray-300 dark:bg-gray-900 dark:border-gray-700 dark:text-white"
          }
          {...register("email", {
            required: {
              value: true,
              message: "Please enter your email",
            },
            pattern: {
              value:
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
              message: "Invalid email address",
            },
          })}
        />
        {errors.email && (
          <span
            className={"text-sm pt-2 flex flex-row items-center text-red-600"}
          >
            <i className={"fa-solid fa-circle-exclamation mr-1"} />
            {errors.email.message}
          </span>
        )}
      </div>
      <div className={"relative flex flex-col"}>
        <label
          className={
            "dark:bg-gray-900 bg-white text-gray-500 dark:text-gray-400 px-2 text-sm absolute -top-2.5 left-2"
          }
        >
          Password
        </label>
        <input
          type={showPassword ? "text" : "password"}
          className={
            "w-full rounded border-gray-300 dark:bg-gray-900 dark:border-gray-700 dark:text-white"
          }
          {...register("password", {
            required: {
              value: true,
              message: "Please enter your password",
            },
          })}
        />
        <span
          className={
            "absolute top-3 right-2 w-8 flex flex-col items-center justify-center text-zinc-500 hover:text-zinc-800 dark:hover:text-zinc-300 cursor-pointer transition-all"
          }
          onClick={() => setShowPassword(!showPassword)}
        >
          <i
            className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
          />
        </span>
        {errors.password && (
          <span
            className={"text-sm pt-2 flex flex-row items-center text-red-600"}
          >
            <i className={"fa-solid fa-circle-exclamation mr-1"} />
            {errors.password.message}
          </span>
        )}
      </div>
      <div className={"flex flex-col items-end mt-3 mb-8"}>
        <Link href={"/login/forgot-password"} passHref>
          <a
            className={
              "text-sm text-gray-500 dark:text-gray-400 hover:text-primary dark:hover:text-primary"
            }
          >
            Forgot password?
          </a>
        </Link>
      </div>
      <div className={"flex flex-col mb-8"}>
        <button
          className={
            "bg-primary hover:bg-primary-400 text-white p-2 rounded transition-all disabled:opacity-10 disabled:pointer-events-none"
          }
          disabled={!isDirty || !isValid}
        >
          Login
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
